import React from 'react';
import gif from '../../assets/images/gif.gif';
import dodLogo from '../../assets/images/logo.jpg';
import banner from '../../assets/images/main-banner.jpg';
import pop_kings from '../../assets/images/pop_kings.jpg';
import {GoldCard} from '../../components/gold-card';
import {HomeWelcome} from '../../components/home-welcome';
import {MintNowButton} from '../../components/mint-button';
import {Team} from '../../components/team';
import {DISCORD_URL, OPENSEA_DODART_SERIES1_URL, OPENSEA_DODART_SERIES2_URL, TWITTER_URL} from '../../constants';
import './index.css';

const Home = () => {
  return (
    <>
      <div className="home-banner-container">
        <img className="home-banner" src={banner} alt="Home Banner" />
      </div>

      <div className="home-about-container" id="about">
        <MintNowButton />
        <p className="text">
          Deck of Degeneracy is a collection of 50 decks of 54 NFT playing cards, 2,700 total NFTs on the Ethereum
          blockchain by Jonathan Little and Wes Henry. All holders can request a physical deck to be mailed to them.
          Your Deck of Degeneracy NFT is also a membership card that gives you access to various potential real-world
          perks listed below. Be sure to join our{' '}
          <a href={DISCORD_URL} target="_blank" rel="noreferrer">
            Discord
          </a>{' '}
          and follow on{' '}
          <a href={TWITTER_URL} target="_blank" rel="noreferrer">
            Twitter
          </a>
          .
        </p>
        <hr className="gray-line mb-5"></hr>

        <HomeWelcome />

        <GoldCard />
        {/* <hr className="gray-line mb-5"></hr> */}

        <h2 className="title">FAIR DISTRIBUTION</h2>
        <div className="home-side-by-side">
          <p className="text">
            All Deck of Degeneracy nfts were initially minted for 0.1eth and were minted randomly, sight unseen.
            Jonathan and Wes retained 150 cards to give to holders of the Bored Apes who let them use their apes in the
            art on the cards and for promotional purposes.
          </p>
          <img className="gif" src={gif} alt="gif" />
        </div>
        <hr className="gray-line mb-5"></hr>

        <h2 className="title">EPIC ART</h2>
        <div className="home-side-by-side">
          <p className="text">
            The Deck of Degeneracy Art Collection{' '}
            <a href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
              Series 1
            </a>{' '}
            and{' '}
            <a href={OPENSEA_DODART_SERIES2_URL} target="_blank" rel="noreferrer">
              Series 2
            </a>{' '}
            feature many pieces of art from some of the biggest names in the nft art space, as well as hot emerging
            artists. Pieces in the Art Collection are randomly given to holders of Deck of Degeneracy by drawing a card
            from a physical deck. If you have the chosen card, you will be airdropped the art.
          </p>
          <div>
            <img className="gif" src={pop_kings} alt="pop" />
            <p className="text-center">Pop Kings, by Pop Wonder</p>
          </div>
        </div>
        <hr className="gray-line mb-5"></hr>
      </div>

      <Team />

      <div className="tail">
        <div className="tail-1">
          <a href="/">
            <img src={dodLogo} alt="Deck of Degeneracy Logo" />
          </a>
        </div>
        <div className="tail-2">Copyright &copy; 2023 Deck of Degeneracy. All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Home;

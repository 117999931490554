import './index.css';

export const MintNowButton = () => {
  return (
    <div className="mint-overall-container" id="mint">
      <h2 className="mint-title">
        <a href="https://opensea.io/collection/deckofdegeneracy" target="_blank" rel="noopener noreferrer">
          GET YOUR CARDS TODAY ON OPENSEA
        </a>
      </h2>
    </div>
  );
};

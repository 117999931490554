import React from 'react';
import {OpenseaLogo, TwitterLogo} from '../../assets/icons/logos';
import jonathan from '../../assets/images/jonathan.jpg';
import rylan from '../../assets/images/rylan.jpg';
import wes from '../../assets/images/wes.jpg';
import './index.css';

const TEAM_MEMBERS = [
  {
    name: 'Wes Henry',
    image: wes,
    description:
      'Wes Henry is a digital fine artist who has become very well known in the nft world and worked with countless global celebrities. Wes Henry\'s Opensea collection alone has traded over 650eth in volume. He is one of the top selling nft artists in the world. "Art is my life. I will continue to improve until the day I die. I\'m just getting started." He also hopes Jonathan will teach him to be a better gambler.',
    twitter: 'https://twitter.com/weshenryart',
    opensea: 'https://opensea.io/collection/wes-henry-art',
  },
  {
    name: 'Jonathan Little',
    image: jonathan,
    description:
      "Jonathan Little is a professional poker player with over $7,000,000 in live tournament cash winnings, the owner of PokerCoaching.com, and author of 15 best-selling poker books. He was voted 2019 Poker Personality of the Year. When he isn't making millions in poker, he LOVES art and nfts, especially those by Wes Henry. Jonathan always enjoys a good gamble.",
    twitter: 'https://twitter.com/JonathanLittle',
    opensea: 'https://opensea.io/jonathanlittle',
  },
  {
    name: 'Rylan Hazelton',
    image: rylan,
    description:
      "Rylan Hazelton is a software engineer with over 35 years of experience. He is the founder of numerous startups and currently works primarily for a big tech company. When he's not writing code, you can find him on his mountain bike, racing cars, or playing poker (or studying on PokerCoaching.com to improve his skills).",
    twitter: 'https://twitter.com/kraziks2k',
    opensea: '',
  },
];

export const Team = () => {
  return (
    <div className="team-container">
      <h2 className="title">THE TEAM</h2>
      <div className="team-members">
        {TEAM_MEMBERS.map(({name, image, description, twitter, opensea}) => (
          <div className="team-member" key={name}>
            <img src={image} alt={name} />
            <p className="text">{description}</p>
            <div className="team-socials">
              {twitter && (
                <a href={twitter} target="_blank" rel="noopener noreferrer">
                  <TwitterLogo />
                </a>
              )}
              {opensea && (
                <a href={opensea} target="_blank" rel="noopener noreferrer">
                  <OpenseaLogo />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

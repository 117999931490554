import {Route, Routes} from 'react-router-dom';
import Navbar from '../components/navbar';
import Admin from '../pages/admin';
import Art from '../pages/art';
import Home from '../pages/home';

const Pages = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="admin" element={<Admin />} />
        <Route path="art" element={<Art />} />
      </Routes>
    </>
  );
};

export default Pages;

import React from 'react';
import diamond_king from '../../assets/images/art-collection/diamond-king.jpg';
import jokes_on_you from '../../assets/images/art-collection/jokes-on-you.jpg';
import queen_of_hearts from '../../assets/images/art-collection/queen-of-hearts.jpg';
import queen_of_spades from '../../assets/images/art-collection/queen-of-spades.jpg';
import dodLogo from '../../assets/images/logo.jpg';
import pop_kings from '../../assets/images/pop_kings.jpg';
import satoshis_nuts from '../../assets/images/satoshis-nuts.jpg';
import ten_high from '../../assets/images/ten-high.jpg';
import the_cozomo_card from '../../assets/images/the-cozomo-card.jpg';
import {
  COD_SITE_URL,
  DOD_SITE_URL,
  ONCYBER_URL,
  OPENSEA_DODART_SERIES1_URL,
  OPENSEA_DODART_SERIES2_URL,
  PERKS_URL,
} from '../../constants';

import './index.css';

const Home = () => {
  return (
    <>
      <div className="art-banner-container">
        <a className="art-banner-link" href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
          <img className="art-banner" src={pop_kings} alt="Pop Kinkgs" />
          <img className="art-banner" src={the_cozomo_card} alt="The Kozomo Card" />
          <img className="art-banner" src={satoshis_nuts} alt="Satoshi's Nuts | Cryptopainter" />
          <img className="art-banner" src={ten_high} alt="Ten High | Made by Megan" />
        </a>
      </div>

      <div className="home-about-container" id="about">
        <hr className="gray-line mb-5"></hr>

        <p className="text">
          <a href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
            The Degeneracy Art Collection Series 1
          </a>{' '}
          And{' '}
          <a href={OPENSEA_DODART_SERIES2_URL} target="_blank" rel="noreferrer">
            The Degeneracy Art Collection Series 2
          </a>{' '}
          features art exclusively for{' '}
          <a href={COD_SITE_URL + '/art'} target="_blank" rel="noreferrer">
            Court of Degeneracy
          </a>{' '}
          and{' '}
          <a href={DOD_SITE_URL + '/art'} target="_blank" rel="noreferrer">
            Deck of Degeneracy
          </a>{' '}
          holders by many of the biggest names and hottest upcoming creators in the nft art space.
        </p>
        <p className="text">
          Holders of the 50 Court of Degeneracy nfts with the "Art" trait in the left hand will be airdropped an edition
          of art once each month for at least one year starting in March 2023.
        </p>
        <p className="text">
          Holders of Deck of Degeneracy who hold nft cards that are chosen to win Art Collection pieces will be
          airdropped them according to this{' '}
          <a href={PERKS_URL} target="_blank" rel="noreferrer">
            spreadsheet
          </a>
        </p>
        <p className="text">
          Be sure to check the{' '}
          <a href={OPENSEA_DODART_SERIES2_URL} target="_blank" rel="noreferrer">
            Series 2 Collection
          </a>{' '}
          because additional pieces are frequently added.
        </p>
        <p className="text">
          You can experience the Degeneracy Art Collection in the metaverse now at{' '}
          <a href={ONCYBER_URL} target="_blank" rel="noreferrer">
            https://oncyber.io/degeneracy
          </a>
        </p>

        <hr className="gray-line mb-5"></hr>
      </div>

      <div className="art-banner-container">
        <a className="art-banner-link" href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
          <img className="art-banner" src={queen_of_hearts} alt="Queen of Hearts | Gabe Weis" />
          <img className="art-banner" src={jokes_on_you} alt="joke's on you | OSF" />
          <img className="art-banner" src={queen_of_spades} alt="Queen of Spades | 3minx" />
          <img className="art-banner" src={diamond_king} alt="Diamond King | Zachary Winterton" />
        </a>
      </div>

      <div className="tail">
        <div className="tail-1">
          <a href="/">
            <img src={dodLogo} alt="Deck of Degeneracy Logo" />
          </a>
        </div>
        <div className="tail-2">Copyright &copy; 2023 Deck of Degeneracy. All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Home;

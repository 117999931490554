import React from 'react';
import {Link} from 'react-router-dom';
import {DiscordLogo, OpenseaLogo, TwitterLogo} from '../../assets/icons/logos';
import dodLogo from '../../assets/images/logo.jpg';
import {DISCORD_URL, OPENSEA_URL, TWITTER_URL} from '../../constants';
import './index.css';

const Navbar = () => {
  return (
    <>
      <div className="navbar-container">
        <div className="navbar-emblem">
          <a href="/">
            <img src={dodLogo} alt="Deck of Degeneracy Logo" />
          </a>
        </div>
        <div className="navbar-items">
          <Link to={`/art/`} className="art-link">
            Art Collection
          </Link>
          <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <DiscordLogo />
          </a>
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <TwitterLogo />
          </a>
          <a href={OPENSEA_URL} target="_blank" rel="noopener noreferrer">
            <OpenseaLogo />
          </a>
          {/*<a href={STORE_URL} target="_blank" rel="noopener noreferrer"><StoreLogo /></a>*/}
        </div>
      </div>
    </>
  );
};

export default Navbar;

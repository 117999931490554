import art from '../../assets/images/art.jpg';
import questionMark from '../../assets/images/questionmark.jpg';
import royal from '../../assets/images/royal.jpg';
import sheet from '../../assets/images/sheet.jpg';
import winnings from '../../assets/images/winnings.jpg';
import {OPENSEA_DODART_SERIES1_URL, OPENSEA_DODART_SERIES2_URL, PERKS_URL} from '../../constants';
import './index.css';

const LIST = [
  {
    img: royal,
    text: 'Party in Las Vegas with Wes and Jonathan for everyone who has five NFTs making a Royal Flush on June 30 2022',
  },
  {
    img: winnings,
    text: "Pieces of Jonathan's poker tournament action",
  },
  {
    img: art,
    text: 'Bonus NFT art',
  },
  {
    img: sheet,
    text: 'Uncut sheets of the entire deck',
  },
  {
    img: questionMark,
    text: "You never know what you're gonna get",
  },
];

export const HomeWelcome = () => {
  return (
    <div className="home-welcome">
      <h2 className="title">WELCOME TO THE CLUB</h2>
      <p className="text">
        When you own a Deck of Degeneracy nft card, you are not just getting a sweet piece of art. You are gaining
        membership to a club with numerous benefits and perks. Most of the perks are given to holders by drawing a
        random card out of a physical deck. All owners of that card will be airdropped the perk.
      </p>
      <p className="text">
        Perks that have been given so far include percentages of world-class poker players including Jonathan Little in
        live poker tournaments, amazing art by some of the biggest names which you can see in the Deck of Degeneracy Art
        Collection{' '}
        <a href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
          Series 1
        </a>{' '}
        and{' '}
        <a href={OPENSEA_DODART_SERIES2_URL} target="_blank" rel="noreferrer">
          Series 2
        </a>
        , real world items such as uncut sheets of cards and tungsten cubes, and and two epic parties, one in Vegas and
        the other in NYC. Over $1,000,000 in utility of various forms has been given back to holders in the project’s
        first year. More perks are being added on a regular basis. You never know what you are going to get!
      </p>
      <div className="home-welcome-list">
        {LIST.map(({img, text}, i) => (
          <div className="home-welcome-list-item" key={`welcome-list-${i}`}>
            <img src={img} alt="img" />
          </div>
        ))}
      </div>
      <p className="text text-center">
        <a href={PERKS_URL} target="_blank" rel="noreferrer">
          Here is the spreadsheet listing all upcoming and past <br /> perks exclusively for Deck of Degeneracy holders.
        </a>
      </p>
    </div>
  );
};

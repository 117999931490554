export const TESTING_MODE = false;

export const DISCORD_URL = 'https://discord.gg/Dna6465WQn';
export const TWITTER_URL = 'https://twitter.com/DegeneracyNFT';
export const OPENSEA_URL = 'https://opensea.io/collection/deckofdegeneracy';
export const LOOKSRARE_URL = 'https://looksrare.org/collections/0x8b3C69aBc8c588E3ECeb2EeDf3bccf8FEA950241';
export const STORE_URL = 'https://www.deckofdegeneracy.shop/';
export const COD_SITE_URL = 'https://www.courtofdegeneracy.com';
export const DOD_SITE_URL = 'https://www.deckofdegeneracy.com';
export const ONCYBER_URL = 'https://oncyber.io/degeneracy';
export const OPENSEA_DODART_SERIES1_URL = 'https://opensea.io/collection/dodart';
export const OPENSEA_DODART_SERIES2_URL = 'https://opensea.io/collection/dodart2';
export const PERKS_URL = 'https://docs.google.com/spreadsheets/d/1fmbGmAnwDMMtessNBryPlelh99Pa0pw_IPGeTt7piOE/';

const MAINNET_CONTRACT_ADDRESS = '0x8b3c69abc8c588e3eceb2eedf3bccf8fea950241';
const TESTNET_CONTRACT_ADDRESS = '0x70FE265a71DCf59F5C160503F1cc825E02E613a4';

export const CONTRACT_ADDRESS = TESTING_MODE ? TESTNET_CONTRACT_ADDRESS : MAINNET_CONTRACT_ADDRESS;
export const WHITELIST_PRICE = 0.1;
export const WHITELIST_LIMIT = 54;
export const REGULAR_PRICE = 0.1;
export const REGULAR_LIMIT = 54;
export const MAX_MINT = 2699;

export const MINT_TIME = TESTING_MODE ? Date.now() + 5000 : 1647050400000;

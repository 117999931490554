import React, {useEffect, useState} from 'react';
import goldCard from '../../assets/images/goldcard.jpg';
import joker from '../../assets/images/JokerM2.jpg';
import './index.css';

export const GoldCard = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const leftImage = <img className="img1" src={joker} alt="img" />;
  const rightImage = <img className="img2" src={goldCard} alt="img" />;

  const goldText = (
    <div className="text">
      <p>
        49 out of the 50 decks feature standard NFTs that all offer the same potential benefits. 1 out of the 50 decks
        are golden and offer double the rewards.
      </p>
      <p>
        Jokers are good. You can use Jokers to complete four and five card hands, such as a Royal Flush. You cannot use
        Jokers to make one or two card hands, such as the Ace of Spades, or Queen of Diamonds, Jack of Clubs.
      </p>
    </div>
  );

  return (
    <div className="gold-card">
      <hr className="gray-line mb-5"></hr>
      <h2 className="title gold-title">SPECIAL CARDS</h2>

      {width < 1000 && (
        <div className="gold-card-mobile">
          {goldText}

          <div className="gold-card-images-mobile">
            {leftImage}
            {rightImage}
          </div>
        </div>
      )}

      {width >= 1000 && (
        <div className="gold-card-regular">
          {leftImage}
          {goldText}
          {rightImage}
        </div>
      )}

      <hr className="gray-line mb-5"></hr>
    </div>
  );
};

import React, {useContext, useState} from 'react';
import {CSVLink} from 'react-csv';
import {TESTING_MODE} from '../../constants';
import {Web3Context} from '../../context/web3-context';

import './index.css';

const storageVersion = '1';
const passwordStorageKey = `admin_password_${storageVersion}`;

const allowedAddresses = {
  '0x6b6ae848f555f70944bc99d736fe29fcaecf8b23': true, // JL
  '0xeed4242f735fa70ed1cf30deae41efb793ea01f0': true, // DOD
  '0xf889dfb9353984d3fae769492e8456305475763f': true, // Rylan
};

const openSeaContractABI = require('../../assets/abis/opensea-abi.json');
const BACKEND_URL = TESTING_MODE ? 'http://localhost:8000' : 'https://backend.deckofdegeneracy.com';

const Index = () => {
  const [cardsValue, setCards] = useState('');
  const [dateValue, setDate] = useState('');
  const [error, setError] = useState('');
  const [ethToSend, setETHToSend] = useState('');
  const [ethToSendBorderColor, setETHToSendBorderColor] = useState('');
  const [openSeaTokenIDToSend, setOpenSeaTokenToSend] = useState('');
  const [password, doSetPassword] = useState(localStorage.getItem(passwordStorageKey));
  const [requestLoading, setRequestLoading] = useState(false);
  const [results, setResults] = useState(undefined);
  const [statuses, setStatuses] = useState({});
  const {address, connect, connected, web3} = useContext(Web3Context);

  function setPassword(value) {
    localStorage.setItem(passwordStorageKey, value);
    return doSetPassword(value);
  }

  async function sendOpenSeaTokenToAll() {
    if (!openSeaTokenIDToSend) {
      alert('Set TokenID To Send Value First');
      return;
    }
    Object.entries(results).forEach(([address, records]) => {
      if (!statuses[address]) {
        sendOpenSeaToken(address, records);
      }
    });
  }

  async function sendETHToAll() {
    if (!ethToSend) {
      alert('Set ETH To Send Value First');
      return;
    } else if (isNaN(ethToSend)) {
      alert('Invalid ETH To Send Value');
      return;
    }
    Object.entries(results).forEach(([address, records]) => {
      if (!statuses[address]) {
        sendETH(address, records);
      }
    });
  }

  async function sendOpenSeaToken(toAddress, records) {
    if (!openSeaTokenIDToSend) {
      alert('Set TokenID To Send Value First');
      return;
    }

    const newStatusValues = {};
    try {
      const openSeaContract = new web3.eth.Contract(openSeaContractABI, '0x495f947276749ce646f68ac8c248420045cb7b5e');
      const safeTransferFrom = openSeaContract.methods.safeTransferFrom(
        address,
        toAddress,
        openSeaTokenIDToSend,
        calculateMultiplier(cardsValue, records),
        '0x'
      );
      const gasEstimate = await safeTransferFrom.estimateGas({from: address});
      await safeTransferFrom.send({
        from: address,
        gas: gasEstimate,
      });
      newStatusValues[toAddress] = <span style={{color: '#999'}}>Sent</span>;
    } catch (error) {
      let message = 'Error';
      if (error.message && error.message.includes('ONLY_CREATOR_ALLOWED')) {
        message = `Error you don't own Token ID ${openSeaTokenIDToSend}`;
      } else if (error.message) {
        message = `Error sending data: (${error.code}): ${error.message}`;
      } else {
        message = `Error sending data: ${error}`;
      }
      console.error(toAddress, message, error);
      newStatusValues[toAddress] = <div style={{color: 'red', margin: '0 auto 5px'}}>Error {message}</div>;
    }
    setStatuses((statuses) => ({
      ...statuses,
      ...newStatusValues,
    }));
  }

  async function sendETH(address, records) {
    if (!ethToSend) {
      alert('Set ETH To Send Value First');
      return;
    } else if (isNaN(ethToSend)) {
      alert('Invalid ETH To Send Value');
      return;
    }
    const multiplier = calculateMultiplier(cardsValue, records);
    web3.eth.sendTransaction(
      {
        to: address,
        from: web3.currentProvider.selectedAddress,
        value: web3.utils.toWei(`${ethToSend * multiplier}`, 'ether'),
      },
      (err, hash) => {
        const newStatusValues = {};
        if (err) {
          console.error(address, err);
          newStatusValues[address] = (
            <div style={{color: 'red', margin: '0 auto 5px'}}>
              Error ({err.code}): {err.message}
            </div>
          );
        } else {
          newStatusValues[address] = <span style={{color: '#999'}}>Sent ({hash})</span>;
        }
        setStatuses((statuses) => ({
          ...statuses,
          ...newStatusValues,
        }));
      }
    );
  }

  function calculateMultiplier(requestCards, records) {
    if (requestCards.split(',').length === 1) {
      // only 1 card, normal multiplier code
      return records.reduce((previous, record) => previous + (record.isGold ? 2 : 1), 0);
    }
    const cardCounts = {};
    const goldCardCounts = {};
    records.forEach((record) => {
      if (!cardCounts[record.card]) {
        cardCounts[record.card] = 0;
      }
      if (!goldCardCounts[record.card]) {
        goldCardCounts[record.card] = 0;
      }
      cardCounts[record.card]++;
      if (record.isGold) {
        goldCardCounts[record.card]++;
      }
    });
    let result = 0;
    Object.values(cardCounts).forEach((count) => {
      if (!result || count < result) {
        result = count;
      }
    });
    let goldResult = 0;
    Object.values(goldCardCounts).forEach((count) => {
      if (!goldResult || count < goldResult) {
        goldResult = count;
      }
    });
    return result + goldResult;
  }

  function renderSuit(suit) {
    switch (suit) {
      case 'c':
        return <span className="clubs">&clubs;</span>;
      case 'd':
        return <span className="diamonds">&diams;</span>;
      case 'h':
        return <span className="hearts">&hearts;</span>;
      case 's':
        return <span className="spades">&spades;</span>;
      case 'm1':
      case 'm2':
        return <span className="joker">{suit}</span>;
      default:
        return <span>??</span>;
    }
  }

  async function makeRequest() {
    setError('');
    setResults(undefined);
    setStatuses({});
    setRequestLoading(true);
    try {
      if (!password) {
        setError('Missing Password');
        setRequestLoading(false);
        return;
      }
      const response = await fetch(`${BACKEND_URL}/holders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cards: cardsValue,
          date: dateValue,
          accessCode: password,
        }),
      });
      if (response.status === 401) {
        setError('401 error: Invalid Password');
        setRequestLoading(false);
        return;
      } else if (response.status !== 200) {
        const body = await response.text();
        setError(`${response.status} error: Invalid Response\nBody: ${body}`);
        setRequestLoading(false);
        return;
      }
      const results = await response.json();
      setResults(results);
      setStatuses({});
      setRequestLoading(false);
    } catch (e) {
      setError(`Error requesting data: ${e}`);
      setRequestLoading(false);
    }
  }

  if (connected) {
    if (allowedAddresses[address.toLowerCase()]) {
      return (
        <div className="admin-panel">
          {error && (
            <div className="error">
              <h3>Error</h3>
              <pre>{error}</pre>
            </div>
          )}
          <div className="admin-password">
            <p>
              <label for="password">Password:</label>
            </p>
            <input
              id="password"
              type="password"
              value={password}
              style={{width: '180px'}}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="admin-request">
            <p>
              <label for="date">Date: (Eastern Timezone - ie: 2022-07-15 18:00:00 or 'now')</label>
            </p>
            <input
              type="text"
              id="date"
              value={dateValue}
              onChange={(e) => {
                setDate(e.target.value);
                setResults(undefined);
                setStatuses({});
              }}
            />
            <p>
              <label for="date">Cards: (Comma delimited, 0 for Joker - ie: 0m1,Ac,Ts)</label>
            </p>
            <input
              type="text"
              id="cards"
              value={cardsValue}
              onChange={(e) => {
                setCards(e.target.value);
                setResults(undefined);
                setStatuses({});
              }}
            />
            <button onClick={makeRequest} disabled={requestLoading}>
              {requestLoading ? 'Loading...' : 'Submit'}
            </button>
          </div>
          {results && (
            <div>
              <h3>
                Addresses that hold {cardsValue} at {dateValue} (Count: {Object.keys(results).length})
              </h3>
              <div style={{margin: '20px auto', display: 'flex'}}>
                <div>
                  Base ETH to Send:{' '}
                  <input
                    type="text"
                    value={ethToSend}
                    style={{
                      width: '35px',
                      borderColor: ethToSendBorderColor,
                      borderWidth: ethToSendBorderColor ? '4px' : '',
                      borderStyle: 'solid',
                    }}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        setETHToSendBorderColor('red');
                      } else {
                        setETHToSendBorderColor('');
                      }
                      setETHToSend(e.target.value);
                    }}
                  />
                  <button onClick={sendETHToAll} disabled={!ethToSend || isNaN(ethToSend)}>
                    Send To All
                  </button>
                </div>
                <div style={{minWidth: '20px'}} />
                <div>
                  OpenSea TokenID to Send:{' '}
                  <input
                    type="text"
                    value={openSeaTokenIDToSend}
                    style={{
                      width: '200px',
                    }}
                    onChange={(e) => {
                      setOpenSeaTokenToSend(e.target.value);
                    }}
                  />
                  <button
                    onClick={sendOpenSeaTokenToAll}
                    disabled={!openSeaTokenIDToSend || openSeaTokenIDToSend.length < 10}
                  >
                    Send To All
                  </button>
                </div>
                <div style={{minWidth: '20px'}} />
                <div>
                  <CSVLink
                    filename={`DOD-holders-${cardsValue}-${dateValue}.csv`}
                    data={Object.entries(results).map(([address, cards]) => ({
                      address,
                      multiplier: calculateMultiplier(cardsValue, cards),
                      cards: cards.map((card) => `${card.card}${card.isGold ? '-gold' : ''}`),
                    }))}
                  >
                    <button>Download CSV</button>
                  </CSVLink>
                </div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <th>Address</th>
                    <th>Cards</th>
                    <th>Multiplier</th>
                    <th></th>
                  </tr>
                  {Object.entries(results).map(([address, cards]) => (
                    <tr key={address} className="response-address">
                      <td>
                        <pre>{address}</pre>
                      </td>
                      <td className="cards">
                        {cards.map((card) => (
                          <span className={'card' + (card.isGold ? ' gold' : '')}>
                            {card.cardValue === '0' ? '[J]' : card.cardValue}
                            {renderSuit(card.suitValue)}
                            <span className="small">({card.card})</span>
                          </span>
                        ))}
                      </td>
                      <td>
                        <span>{calculateMultiplier(cardsValue, cards)}x</span>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        {statuses[address] ? (
                          statuses[address]
                        ) : (
                          <>
                            <button
                              style={{margin: 'auto'}}
                              disabled={!ethToSend || isNaN(ethToSend)}
                              onClick={() => sendETH(address, cards)}
                            >
                              Send ETH
                            </button>{' '}
                            <button
                              style={{margin: 'auto'}}
                              disabled={!openSeaTokenIDToSend || openSeaTokenIDToSend.length < 10}
                              onClick={() => sendOpenSeaToken(address, cards)}
                            >
                              Send OpenSea Token
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div style={{marginBottom: 100}}></div>
        </div>
      );
    } else {
      return (
        <div style={{padding: '50px', textAlign: 'center'}}>
          <h3>Not authorized</h3>
          <p>{address}</p>
        </div>
      );
    }
  }

  // need connection
  return (
    <div style={{padding: '50px', textAlign: 'center'}}>
      <button onClick={connect}>Connect to MetaMask for Access</button>
    </div>
  );
};

export default Index;
